/* eslint-disable */
import { Stack } from '@mui/material';
import React from 'react';
import { PocetnaPage } from './landing-page/PocetnaPage';
import { TkoSmoMiPage } from './landing-page/TkoSmoMiPage';
import { TimoviPage } from './landing-page/TimoviPage';
import { RadionicePage } from './landing-page/RadionicePage';
import { OdborPage } from './landing-page/OdborPage';
import { Footer } from './universal/Footer';

export const Home = () => {
    return (
        // <Container style={landingPageBackgroundStyle}>
        //     <Button onClick={() => navigate('/register')}>Register</Button>
        //     <Button onClick={() => navigate('/login')}>Login</Button>
        // </Container>
        <Stack>
            <PocetnaPage />
            <TkoSmoMiPage />
            <TimoviPage />
            <RadionicePage />
            <OdborPage />
            <Footer />
        </Stack>
    );
};
