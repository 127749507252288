import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Home } from './Home';
import { GuestPage } from './user-management/GuestPage';
import { ThemeProvider } from '@emotion/react';
import { theme } from '../themes/theme';
import CssBaseline from '@mui/material/CssBaseline';

export const Main = () => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Router>
                <Routes>
                    <Route path={'/'} element={<Navigate to={`/home`} />} />
                    <Route path={`/home`} element={<Home />} />
                    <Route path={`/register`} element={<GuestPage isLogin={false} />} />
                    <Route path={`/login`} element={<GuestPage isLogin={true} />} />
                    {/* <Route path="/profile" element={<Protected element={<Profile />} path={'/profile'} />} />
                <Route path="/GDPR" element={<GDPR />} /> */}
                </Routes>
            </Router>
        </ThemeProvider>
    );
};
