/* eslint-disable */
import React, { useState } from 'react';
import dayjs from 'dayjs';

import {
    Box,
    Typography,
    Grid,
    Link,
    InputAdornment,
    TextField,
    Button,
    Checkbox,
    Alert,
    Stack,
    IconButton,
    FormControl,
    Stepper,
    Step,
    StepButton,
    MenuItem,
    InputLabel,
    Select,
    OutlinedInput,
    Chip
} from '@mui/material';
import { Visibility, VisibilityOff, Cancel, Check } from '@mui/icons-material';
import { DateField, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import axios from '../../api/axios';
import { ValidateEmail, ValidatePassword, ValidatePhoneNumber } from '../../utils/functions';
import {
    alertBoxStyle,
    alertStyle,
    buttonStyle,
    buttonTextStyle,
    gdprLinkStyle,
    gridContainerStyle,
    gridItemStyle,
    textFieldStyle,
    topTextStyle,
    tosStyle
} from '../../styles';

type RegisterResponse = {
    success: boolean;
    status: string;
    message: string;
    data: {
        access: string;
        refresh: string;
    };
};

type RegisterFormProps = {
    onRegistrationSuccess: () => void;
};

type RegisterTemporaryData = {
    firstName: string | undefined;
    lastName: string | undefined;
    email: string | undefined;
    phoneNumber: string | undefined;
    // password: string | undefined;
    // repeatPassword: string | undefined;
    acceptedGDPR: boolean;
    dateOfBirth: dayjs.Dayjs | undefined;
    faculty: string | undefined;
    academicYear: number | undefined;
    interests: string | undefined;
    joiningReasons: string[] | undefined;
    teamChoice: string[] | undefined;
    shirtSize: string | undefined;
};

const steps = [0, 1, 2];
const yearClasses = [1, 2, 3, 4, 5];
const enlistmentReasons = [
    'Networking',
    'Sudjelovanje u radionicama',
    'Osobni napredak',
    'Zabava/druženje'
];

type Team = {
    [key: string]: string;
};

const teams: Team = {
    Fundraising: 'FR',
    'Public Relations': 'PR',
    IT: 'IT',
    Design: 'Design',
    'Human Resources': 'HR',
    Grant: 'Grant team'
};

const shirtSizes = ['S', 'M', 'L', 'XL'];

export const RegisterTemporary = ({ onRegistrationSuccess }: RegisterFormProps) => {
    const [step, setStep] = useState(0);

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const [formData, setFormData] = useState<RegisterTemporaryData>({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        // password: '',
        // repeatPassword: '',
        acceptedGDPR: false,
        dateOfBirth: dayjs(''),
        faculty: '',
        academicYear: 0,
        interests: '',
        joiningReasons: [],
        teamChoice: [],
        shirtSize: ''
    });

    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        // password: '',
        // repeatPassword: '',
        acceptedGDPR: '',
        submit: '',
        dateOfBirth: '',
        faculty: '',
        academicYear: '',
        interests: '',
        joiningReasons: '',
        teamChoice: '',
        shirtSize: ''
    });

    const validateForm = () => {
        const errors = {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            // password: '',
            // repeatPassword: '',
            acceptedGDPR: '',
            // subscribedToMail: '',
            submit: '',
            dateOfBirth: '',
            faculty: '',
            academicYear: '',
            interests: '',
            joiningReasons: '',
            teamChoice: '',
            shirtSize: ''
        };

        if (formData != null) {
            if (!formData.firstName) {
                errors.firstName = 'Potrebno je unjeti ime';
            }

            if (!formData.lastName) {
                errors.lastName = 'Potrebno je unjeti prezime';
            }

            if (!ValidateEmail(formData.email)) {
                errors.email = 'Neispravan E-mail';
            }

            if (!ValidatePhoneNumber(formData.phoneNumber)) {
                errors.phoneNumber = 'Neispravan format telefonskog broja';
            }

            // if (!ValidatePassword(formData.password)) {
            //     errors.password = 'Neispravan format lozinke (potrebno je minimalno 8 znakova)';
            // }

            // if (formData.repeatPassword !== formData.password) {
            //     errors.repeatPassword = 'Lozinke se ne podudaraju';
            // }

            if (!formData.acceptedGDPR) {
                errors.acceptedGDPR =
                    'Nije moguće stvoriti korisnički račun bez prihvaćanja privole za obradom podataka';
            }
            if (!formData.dateOfBirth) {
                errors.dateOfBirth = 'Neispravan datum rođenja';
            }
            if (!formData.faculty) {
                errors.faculty = 'Neispravan unos fakulteta';
            }
            if (!formData.academicYear) {
                errors.academicYear = 'Neispravan unos godine studiranja';
            }
            if (!formData.joiningReasons) {
                errors.joiningReasons = 'Neispravan unos razloga za učlanjenje';
            }
            if (!formData.teamChoice) {
                errors.teamChoice = 'Neispravan odabir tima';
            }
            if (!formData.shirtSize) {
                errors.shirtSize = 'Neispravan unos veličine majice';
            }

            setFormErrors(errors);
        }

        return !Object.values(errors).some((error) => error);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                const requestData = {
                    firstName: formData?.firstName as string,
                    lastName: formData?.lastName as string,
                    email: formData?.email as string,
                    // password: formData?.password as string,
                    dateOfBirth: dayjs(formData?.dateOfBirth).toDate() as Date,
                    phoneNumber: formData?.phoneNumber as string,
                    faculty: formData?.faculty as string,
                    academicYear: formData?.academicYear as number,
                    interests: formData?.interests as string,
                    joiningReasons: formData?.joiningReasons as string[],
                    teams: formData?.teamChoice?.map((e: string) => teams[e]) as string[],
                    shirtSize: formData?.shirtSize as string

                    //acceptedGDPR: formData?.acceptedGDPR as boolean,
                };

                const config = {
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: '*/*'
                    }
                };

                console.log(requestData);

                const response = await axios.post<RegisterResponse>(
                    '/register/temporary',
                    requestData,
                    config
                );

                if (response.status == 200) {
                    setFormData({
                        firstName: '',
                        lastName: '',
                        email: '',
                        phoneNumber: '',
                        // password: '',
                        // repeatPassword: '',
                        acceptedGDPR: false,
                        dateOfBirth: dayjs(''),
                        faculty: '',
                        academicYear: 0,
                        interests: '',
                        joiningReasons: [],
                        teamChoice: [],
                        shirtSize: ''
                    });
					onRegistrationSuccess();
                }
            } catch (error) {
                setFormErrors((prevErrors) => ({ ...prevErrors, submit: 'Bad response' }));
            }
        }
    };

    return (
        <FormControl>
            <Grid container spacing={0} sx={gridContainerStyle}>
                <Grid item xs={12} sx={gridItemStyle}>
                    <Typography sx={topTextStyle}>Postani novi član.</Typography>
                </Grid>
                <Grid item sx={{ ...gridItemStyle, width: '100%' }}>
                    <Box sx={{ width: '100%', margin: '1rem 0 ' }}>
                        <Stepper nonLinear activeStep={step}>
                            {steps.map((label) => (
                                <Step key={label}>
                                    <StepButton
                                        color="inherit"
                                        onClick={() => setStep(label)}></StepButton>
                                </Step>
                            ))}
                        </Stepper>
                    </Box>
                </Grid>
                {step === 0 && (
                    <>
                        <Grid item xs={6} sx={gridItemStyle}>
                            <TextField
                                id="firstName"
                                type="text"
                                label="Ime"
                                style={textFieldStyle}
                                value={formData != null ? formData.firstName : ''}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        firstName: e.target.value
                                    } as RegisterTemporaryData)
                                }
                            />
                        </Grid>

                        <Grid item xs={6} sx={gridItemStyle}>
                            <TextField
                                id="lastName"
                                type="text"
                                label="Prezime"
                                style={textFieldStyle}
                                value={formData != null ? formData.lastName : ''}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        lastName: e.target.value
                                    } as RegisterTemporaryData)
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sx={gridItemStyle}>
                            <TextField
                                id="email"
                                type="text"
                                label="E-mail adresa"
                                style={textFieldStyle}
                                value={formData != null ? formData.email : ''}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        email: e.target.value
                                    } as RegisterTemporaryData)
                                }
                            />
                        </Grid>

                        <Grid item xs={12} sx={gridItemStyle}>
                            <TextField
                                id="phoneNumber"
                                type="number"
                                label="Broj telefona (npr. 0919876543)"
                                style={textFieldStyle}
                                value={formData != null ? formData.phoneNumber : ''}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        phoneNumber: e.target.value
                                    } as RegisterTemporaryData)
                                }
                            />
                        </Grid>

                        {/* <Grid item xs={12} sx={gridItemStyle}>
                            <TextField
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                label="Nova lozinka"
                                style={textFieldStyle}
                                value={formData != null ? formData.password : ''}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        password: e.target.value
                                    } as RegisterTemporaryData)
                                }
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end">
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid> */}

                        {/* <Grid item xs={12} sx={gridItemStyle}>
                            <TextField
                                id="repeatPassword"
                                type="password"
                                label="Potvrdi lozinku"
                                style={textFieldStyle}
                                value={formData != null ? formData.repeatPassword : ''}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        repeatPassword: e.target.value
                                    } as RegisterTemporaryData)
                                }
                            />
                        </Grid> */}
                    </>
                )}

                {step === 1 && (
                    <>
                        <Grid item xs={12} sx={gridItemStyle}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateField
                                    label="Datum rođenja"
                                    style={textFieldStyle}
                                    value={
                                        formData != null ? dayjs(formData.dateOfBirth) : dayjs('')
                                    }
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            dateOfBirth: e
                                        } as RegisterTemporaryData)
                                    }
                                    format="DD/MM/YYYY"
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={12} sx={gridItemStyle}>
                            <TextField
                                id="faculty"
                                type="text"
                                label="Fakultet"
                                style={textFieldStyle}
                                value={formData != null ? formData.faculty : ''}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        faculty: e.target.value
                                    } as RegisterTemporaryData)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sx={gridItemStyle}>
                            <TextField
                                id="academicYear"
                                select
                                label="Godina studija"
                                value={formData != null ? formData.academicYear : 1}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        academicYear: Number(e.target.value)
                                    } as RegisterTemporaryData)
                                }
                                style={textFieldStyle}>
                                {yearClasses.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sx={gridItemStyle}>
                            <TextField
                                id="interests"
                                type="text"
                                label="Interesi (opcionalno)"
                                multiline
                                rows={4}
                                style={textFieldStyle}
                                value={formData != null ? formData.interests : ''}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        interests: e.target.value
                                    } as RegisterTemporaryData)
                                }
                            />
                        </Grid>
                    </>
                )}

                {step === 2 && (
                    <>
                        <Grid item xs={12} sx={gridItemStyle}>
                            <FormControl sx={textFieldStyle}>
                                <InputLabel>Razlog učlanjenja</InputLabel>
                                <Select
                                    multiple
                                    value={formData != null ? formData.joiningReasons : []}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            joiningReasons: e.target.value
                                        } as RegisterTemporaryData)
                                    }
                                    input={<OutlinedInput label="Razlog učlanjenja" />}
                                    renderValue={(selected) => (
                                        <Stack gap={1} direction="row" flexWrap="wrap">
                                            {selected?.map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={value}
                                                    onDelete={() =>
                                                        setFormData({
                                                            ...formData,
                                                            joiningReasons:
                                                                formData.joiningReasons?.filter(
                                                                    (item) => item !== value
                                                                )
                                                        } as RegisterTemporaryData)
                                                    }
                                                    deleteIcon={
                                                        <Cancel
                                                            onMouseDown={(e: React.MouseEvent) =>
                                                                e.stopPropagation()
                                                            }
                                                        />
                                                    }
                                                />
                                            ))}
                                        </Stack>
                                    )}>
                                    {enlistmentReasons.map((name) => (
                                        <MenuItem
                                            key={name}
                                            value={name}
                                            sx={{ justifyContent: 'space-between' }}>
                                            {name}
                                            {formData.joiningReasons?.includes(name) ? (
                                                <Check color="info" />
                                            ) : null}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={gridItemStyle}>
                            <FormControl sx={textFieldStyle}>
                                <InputLabel>Odabir tima</InputLabel>
                                <Select
                                    sx={textFieldStyle}
                                    multiple
                                    value={formData != null ? formData.teamChoice : []}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            teamChoice: e.target.value
                                        } as RegisterTemporaryData)
                                    }
                                    input={<OutlinedInput label="Odabir tima" />}
                                    renderValue={(selected) => (
                                        <Stack gap={1} direction="row" flexWrap="wrap">
                                            {selected?.map((value) => (
                                                <Chip
                                                    key={value}
                                                    label={value}
                                                    onDelete={() =>
                                                        setFormData({
                                                            ...formData,
                                                            teamChoice: formData.teamChoice?.filter(
                                                                (item) => item !== value
                                                            )
                                                        } as RegisterTemporaryData)
                                                    }
                                                    deleteIcon={
                                                        <Cancel
                                                            onMouseDown={(e: React.MouseEvent) =>
                                                                e.stopPropagation()
                                                            }
                                                        />
                                                    }
                                                />
                                            ))}
                                        </Stack>
                                    )}>
                                    {Object.keys(teams).map((name) => (
                                        <MenuItem
                                            key={name}
                                            value={name}
                                            sx={{ justifyContent: 'space-between' }}>
                                            {name}
                                            {formData.teamChoice?.includes(name) ? (
                                                <Check color="info" />
                                            ) : null}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sx={gridItemStyle}>
                            <TextField
                                id="shirtSize"
                                select
                                label="Veličina majice"
                                value={formData != null ? formData.shirtSize : ''}
                                onChange={(e) =>
                                    setFormData({
                                        ...formData,
                                        shirtSize: e.target.value
                                    } as RegisterTemporaryData)
                                }
                                style={textFieldStyle}>
                                {shirtSizes.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sx={gridItemStyle}>
                            <Grid container>
                                <Grid item xs={2}>
                                    <Checkbox
                                        name="acceptedGDPR"
                                        onChange={(e) =>
                                            setFormData({
                                                ...formData,
                                                acceptedGDPR: e.target.checked
                                            } as RegisterTemporaryData)
                                        }
                                        checked={formData.acceptedGDPR}
                                    />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography sx={tosStyle}>
                                        Pritiskom gumba "Učlani se" potvrđujete ispravnost unesenih
                                        podataka i prihvaćate{' '}
                                        <Link component="span" sx={gdprLinkStyle}>
                                            Privolu za obrađivanje podataka
                                        </Link>
                                        . Za sva pitanja vezana uz GDPR možete se javiti na
                                        info@eestec.hr.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sx={gridItemStyle}>
                            <Button sx={buttonStyle('red')} onClick={handleSubmit}>
                                <Typography sx={buttonTextStyle()}>Učlani se</Typography>
                            </Button>
                        </Grid>
                    </>
                )}
                {step !== 2 && (
                    <Grid item xs={12} sx={gridItemStyle}>
                        <Button
                                sx={buttonStyle('red')}
                                variant="contained"
                                onClick={() => setStep((prevStep) => prevStep + 1)}
                            >
                                Sljedeće
                            </Button>
                    </Grid>
                )}

                <Grid item sx={gridItemStyle}>
                    <Stack>
                        <Box sx={alertBoxStyle}>
                            {formErrors.firstName && (
                                <Alert severity="error" sx={alertStyle}>
                                    {formErrors.firstName}
                                </Alert>
                            )}
                        </Box>
                        <Box sx={alertBoxStyle}>
                            {formErrors.lastName && (
                                <Alert severity="error" sx={alertStyle}>
                                    {formErrors.lastName}
                                </Alert>
                            )}
                        </Box>
                        <Box sx={alertBoxStyle}>
                            {formErrors.email && (
                                <Alert severity="error" sx={alertStyle}>
                                    {formErrors.email}
                                </Alert>
                            )}
                        </Box>
                        <Box sx={alertBoxStyle}>
                            {formErrors.phoneNumber && (
                                <Alert severity="error" sx={alertStyle}>
                                    {formErrors.phoneNumber}
                                </Alert>
                            )}
                        </Box>
                        {/* <Box sx={alertBoxStyle}>
                            {formErrors.password && (
                                <Alert severity="error" sx={alertStyle}>
                                    {formErrors.password}
                                </Alert>
                            )}
                        </Box>
                        <Box sx={alertBoxStyle}>
                            {formErrors.repeatPassword && (
                                <Alert severity="error" sx={alertStyle}>
                                    {formErrors.repeatPassword}
                                </Alert>
                            )}
                        </Box> */}
                        <Box sx={alertBoxStyle}>
                            {formErrors.dateOfBirth && (
                                <Alert severity="error" sx={alertStyle}>
                                    {formErrors.dateOfBirth}{' '}
                                </Alert>
                            )}
                        </Box>
                        <Box sx={alertBoxStyle}>
                            {formErrors.faculty && (
                                <Alert severity="error" sx={alertStyle}>
                                    {formErrors.faculty}{' '}
                                </Alert>
                            )}
                        </Box>
                        <Box sx={alertBoxStyle}>
                            {formErrors.academicYear && (
                                <Alert severity="error" sx={alertStyle}>
                                    {formErrors.academicYear}{' '}
                                </Alert>
                            )}
                        </Box>
                        <Box sx={alertBoxStyle}>
                            {formErrors.joiningReasons && (
                                <Alert severity="error" sx={alertStyle}>
                                    {formErrors.joiningReasons}{' '}
                                </Alert>
                            )}
                        </Box>
                        <Box sx={alertBoxStyle}>
                            {formErrors.teamChoice && (
                                <Alert severity="error" sx={alertStyle}>
                                    {formErrors.teamChoice}{' '}
                                </Alert>
                            )}
                        </Box>
                        <Box sx={alertBoxStyle}>
                            {formErrors.shirtSize && (
                                <Alert severity="error" sx={alertStyle}>
                                    {formErrors.shirtSize}{' '}
                                </Alert>
                            )}
                        </Box>
                        <Box sx={alertBoxStyle}>
                            {formErrors.acceptedGDPR && (
                                <Alert severity="error" sx={alertStyle}>
                                    {formErrors.acceptedGDPR}{' '}
                                </Alert>
                            )}
                        </Box>
                        <Box sx={alertBoxStyle}>
                            {formErrors.submit && (
                                <Alert severity="error" sx={alertStyle}>
                                    {formErrors.submit}
                                </Alert>
                            )}
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
        </FormControl>
    );
};
