import React, { useState } from 'react';
import { Box, Typography, Grid, Link } from '@mui/material';
import {
    centeredStyle,
    landingPageTitle,
    landingPageText,
    componentWrapper,
    componentContainer
} from '../../styles';
import { ImageSlider } from '../common/ImageSlider';

const radionice = [
    {
        title: 'WES',
        order: 1,
        titleURL: '',
        subtitle: 'Workshop on Embedded Systems',
        text: 'Radionica ugradbenih računalnih sustava omogućuje studentima da nadograde teoretsko znanje kroz praktične zadatke i uče od industrijskih predavača, pripremajući ih za realne inženjerske izazove.',
        image: '/images/radionice/wes.png',
        instagramLink: 'https://instagram.com/wes_zg',
        linkedInLink:
            'https://www.linkedin.com/in/workshop-on-embedded-systems-wes-eestec-lc-zagreb-708728157/',
        facebookLink: ''
    },
    {
        title: 'PLC',
        order: 2,
        titleURL: '',
        subtitle: 'Programmable Logic Controllers Workshop',
        text: 'Ova radionica istražuje važnost PLC-ova u automatizaciji, od industrijskih procesa do zabavnih parkova, pomažući u prevazilaženju straha od tehnologije i omogućujući praktičnu primjenu.',
        image: '/images/radionice/plc.jpeg',
        instagramLink: 'https://instagram.com/plc_zagreb',
        linkedInLink: 'https://www.linkedin.com/showcase/plc-workshop/',
        facebookLink: ''
    },
    {
        title: 'NeoData',
        order: 3,
        titleURL: '',
        subtitle: 'Data Science Workshop',
        text: 'Pruža uvid u svijet Data Science kroz praktična predavanja industrijskih stručnjaka, omogućujući studentima da prošire znanje i suoče se s realnim izazovima u analizi podataka.',
        image: '/images/radionice/neodata.jpg',
        instagramLink: 'https://instagram.com/neodata_zg',
        linkedInLink: '',
        facebookLink: 'https://www.facebook.com/profile.php?id=100087049967510'
    },
    {
        title: 'FrontEd',
        order: 4,
        titleURL: '',
        subtitle: 'Web Development Workshop',
        text: 'Naš program web developmenta nudi sveobuhvatni uvid u web development, korisničko iskustvo i napredne tehnologije, obogaćen dodatnim resursima i ekspertizom za nezaboravno iskustvo učenja i prakse.',
        image: '/images/radionice/fronted.jpeg',
        instagramLink: 'https://instagram.com/fronted.zg',
        linkedInLink: 'https://www.linkedin.com/company/eestec-fronted/',
        facebookLink: ''
    },
    {
        title: 'Illuminative',
        order: 5,
        titleURL: '',
        subtitle: 'Mobile Development Workshop',
        text: 'Radionica omogućuje studentima da savladaju razvoj mobilnih aplikacija i razviju programersko teško razmišljanje, nudeći priliku za stjecanje konkurentnih vještina izvan tradicionalnog kurikuluma.',
        image: '/images/radionice/illuminative.jpg',
        instagramLink: 'https://instagram.com/illuminative.zg',
        linkedInLink: 'https://www.linkedin.com/showcase/illuminative-zagreb/',
        facebookLink: ''
    }
];

const styles = {
    componentWrapper: {
        ...componentWrapper,
        backgroundColor: '#B00000'
    },
    componentContainer: {
        ...componentContainer,
        zIndex: 2,
        width: {
            xs: '90%'
        },
        justifyContent: {
            xs: 'flex-start'
        }
    },
    contentContainer: {
        display: 'flex',
        alignItems: 'center',
        height: {
            xs: '60%'
        }
    },
    textContainer: {
        ...centeredStyle,
        flexWrap: 'wrap',
        padding: '20px',
        alignItems: 'flex-start',
        marginTop: {
            xl: '3rem',
            lg: '1rem'
        }
    },
    landingPageText: {
        ...landingPageText,
        fontSize: {
            xs: '1rem',
            sm: '1.3rem',
            lg: '1.5rem'
        }
    },
    landingPageTitle: {
        ...landingPageTitle,
        marginTop: '0px',
        fontSize: {
            xs: '2.5rem',
            sm: '3.5rem',
            lg: '5rem'
        }
    },
    radioniceContainer: {
        ...centeredStyle,
        background: 'radial-gradient(50% 50% at 50% 50%, #F8EEEE 0%, #CDCDCD 100%)',
        borderRadius: '9px',
        padding: '10px'
    },
    radioniceText: {
        ...landingPageText,
        margin: '1vw 0px ',
        color: '#B00000',
        textAlign: 'center',
        fontSize: {
            xs: '1rem',
            sm: '1.3rem',
            lg: '1.7rem'
        }
    },
    radioniceTitle: {
        ...landingPageTitle,
        color: '#B00000',
        marginTop: '0px',
        fontSize: {
            xs: '1.3rem',
            lg: '2rem'
        }
    },
    imageSliderWrapper: {
        height: {
            xl: '80%',
            lg: '60%',
            md: '75%',
            sm: '60%',
            xs: '50%'
        },
        width: '100%'
    },
    backgroundImageGradient: {
        position: 'absolute',
        width: ' 70%',
        top: 0,
        right: 0,
        bottom: 0,
        backgroundImage:
            'linear-gradient(90deg,#B00000 17.19%,rgba(255,255,255,0) 68.23%),url(/images/background-images/radionice-background-image.png)'
    },
    iconsContainer: {
        marginTop: '10px',
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '40%',
        height: {
            md: '45px',
            xs: '35px'
        }
    },
    icon: {
        height: {
            md: '45px',
            xs: '35px'
        }
    }
};

export const RadionicePage = () => {
    radionice.sort((a, b) => a.order - b.order);

    const radioniceImages = radionice.map((e) => e.image);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleIndexChange = (newIndex: number) => {
        setCurrentIndex(newIndex);
    };
    return (
        <Box sx={styles.componentWrapper}>
            <Box sx={styles.componentContainer}>
                <Box sx={[styles.textContainer]}>
                    <Typography sx={styles.landingPageTitle}>RADIONICE</Typography>
                    <Grid container>
                        <Grid item xs={12} sm={12} md={12} lg={10}>
                            <Typography sx={styles.landingPageText}>
                                Glavni cilj naših radionica je naučiti mlade ljude, buduće inženjere
                                osnovna znanja i vještine vezane za različita tehnička područja. Te
                                vještine nadilaze nastavne programe, a zainteresiranim studentima
                                pomogle bi u produbljivanju interesa i karijernom usmjerenju.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>

                <Grid container sx={styles.contentContainer}>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={8}
                        sx={[centeredStyle, styles.imageSliderWrapper]}>
                        <ImageSlider
                            images={radioniceImages}
                            onIndexChange={handleIndexChange}
                            lgImageWidth="75%"
                            mdImageWidth="75%"
                            smImageWidth="80%"
                            xsImageWidth="95%"
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <Box sx={styles.radioniceContainer}>
                            {radionice[currentIndex].titleURL ? (
                                <Link href={radionice[currentIndex].titleURL}>
                                    <Typography sx={styles.radioniceTitle}>
                                        {radionice[currentIndex].title}
                                    </Typography>
                                </Link>
                            ) : (
                                <Typography sx={styles.radioniceTitle}>
                                    {radionice[currentIndex].title}
                                </Typography>
                            )}
                            <Typography sx={styles.radioniceText}>
                                {radionice[currentIndex].subtitle}
                            </Typography>
                            <Typography sx={styles.radioniceText}>
                                {radionice[currentIndex].text}
                            </Typography>
                            <Box sx={styles.iconsContainer}>
                                {radionice[currentIndex].instagramLink && (
                                    <Link href={radionice[currentIndex].instagramLink}>
                                        <Box
                                            sx={styles.icon}
                                            component="img"
                                            src={require('../../resources/icons/instagram-icon.png')}
                                        />
                                    </Link>
                                )}
                                {radionice[currentIndex].facebookLink && (
                                    <Link href={radionice[currentIndex].facebookLink}>
                                        <Box
                                            sx={styles.icon}
                                            component="img"
                                            src={require('../../resources/icons/facebook-icon.png')}
                                        />
                                    </Link>
                                )}

                                {radionice[currentIndex].linkedInLink && (
                                    <Link href={radionice[currentIndex].linkedInLink}>
                                        <Box
                                            sx={styles.icon}
                                            component="img"
                                            src={require('../../resources/icons/linkedin-icon.png')}
                                        />
                                    </Link>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={styles.backgroundImageGradient}></Box>
        </Box>
    );
};
