import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

type SuccessModalProps = {
    open: boolean;
    handleClose: () => void;
};

function SuccessModal({ open = false, handleClose }: SuccessModalProps) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        borderRadius: 10,
        p: 4
    };

    const titleStyle = {
        fontSize: '24px',
        fontWeight: 'bold',
        mb: 2
    };

    const buttonStyle = {
        borderRadius: 50,
        bgcolor: 'red',
        color: 'white',
        mt: 2
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="success-modal-title"
            aria-describedby="success-modal-description">
            <Box sx={style}>
                <Typography id="success-modal-title" variant="h6" component="h2" sx={titleStyle}>
                    Uspješno si registriran!
                </Typography>
                <Typography id="success-modal-description" sx={{ mt: 2 }}>
                    Dođi na sastanak i pokupi svoju prvu EESTEC majcu.
                </Typography>
                <Button onClick={handleClose} sx={buttonStyle}>
                    Zatvori
                </Button>
            </Box>
        </Modal>
    );
}

export default SuccessModal;
