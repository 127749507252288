import React from 'react';
import { Box, Typography } from '@mui/material';
import {
    centeredStyleRow,
    landingPageTitle,
    landingPageText,
    componentWrapper,
    componentContainer
} from '../../styles';
import { ImageSlider } from '../common/ImageSlider';

const timovi = [
    {
        title: 'HUMAN RESOURCES',
        order: 1,
        text: 'Zadužen za zabavu! Organiziraju partije i evente gdje se novi i stari članovi mogu upoznati i stvoriti trajne uspomene. Oni stvaraju prilike za druženje koje čine našu organizaciju posebnom.',
        image: '/images/teams/team_cp.png'
    },
    {
        title: 'PUBLIC RELATIONS',
        order: 2,
        text: 'Srce komunikacije između nas i vanjskog svijeta. Ne samo da promovira naše vrijednosti, već i gradi povjerenje s medijima i partnerima, osiguravajući da nas javnost vidi u najboljem svjetlu.',
        image: '/images/teams/team_pr.png'
    },
    {
        title: 'FUNDRAISING',
        order: 3,
        text: 'Most između nas i sponzora. Komuniciraju direktno s firmama, pregovaraju i dogovaraju financiranje EESTEC radionica. Oni su naša prva linija komunikacije s poslovnim svijetom i ključni za uspješno partnerstvo.',
        image: '/images/teams/team_fr.png'
    },
    {
        title: 'GRANT',
        order: 4,
        text: 'Pero i papir kada je riječ o natječajima. Fokusirani su na pisanje i prijavljivanje na razne natječaje, osiguravajući da naši projekti i inicijative dobiju potrebna sredstva i podršku.',
        image: '/images/teams/team_cp.png'
    },
    {
        title: 'DESIGN',
        order: 5,
        text: 'Prenosi našu viziju kroz vizualne medije. Njihova kreativnost čini da se naš brand ističe i komunicira našu priču na jedinstven i atraktivan način.',
        image: '/images/teams/team_design.png'
    },
    {
        title: 'IT',
        order: 6,
        text: 'Naša tehnološka snaga. Brinu o IT potrebama članova EESTEC-a, održavaju i unapređuju naše tehnološke alate te rade na stvarnim projektima kako za nas, tako i za vanjske klijente.',
        image: '/images/teams/team_it.png'
    }
];

const styles = {
    componentContainer: {
        ...componentContainer,
        justifyContent: 'space-evenly',
        width: {
            xs: '90%',
            lg: '80%'
        }
    },
    landingPageText: {
        ...landingPageText,
        maxWidth: {
            xs: '90%',
            sm: '60%'
        },
        fontSize: {
            xs: '1rem',
            sm: '1.3rem',
            lg: '1.5rem'
        },
        padding: '0px 5px 0px 10px'
    },
    landingPageTitle: {
        ...landingPageTitle,
        marginTop: '0px',
        marginBottom: '10px',
        borderBottom: '1px solid #F8EEEE',
        fontSize: {
            xs: '2.5rem',
            sm: '3.5rem',
            lg: '5rem'
        }
    },
    textContainer: {
        ...centeredStyleRow,
        flexWrap: 'wrap',
        backgroundColor: '#B00000',
        border: '1px solid #F8EEEE',
        borderRadius: '32px',
        padding: '20px',
        justifyContent: 'space-evenly',
        marginTop: {
            xs: '1rem',
            sm: '3rem'
        }
    },
    imageSliderWrapper: {
        height: {
            md: '66%',
            xs: '50%'
        },
        width: {
            lg: '100%',
            md: '120%',
            xs: '100%'
        }
    },
    teamIcon: {
        display: {
            xs: 'none',
            md: 'block'
        }
    }
};

export const TimoviPage = () => {
    timovi.sort((a, b) => a.order - b.order);

    return (
        <Box sx={componentWrapper}>
            <Box sx={styles.componentContainer}>
                <Box sx={[centeredStyleRow, { justifyContent: 'space-between' }]}>
                    <Box sx={[styles.textContainer]}>
                        <Typography sx={styles.landingPageTitle}>TIMOVI</Typography>
                        <Typography sx={styles.landingPageText}>
                            Aktivni članovi Udruge podijeljeni su po preferencijskim grupama. Udrugu
                            čini ukupno šest timova koji kvalitetno pokrivaju sva područja potrebna
                            za uspješno djelovanje organizacije, a predvođeni su koordinatorima koji
                            čine Upravni odbor.
                        </Typography>
                        <Box
                            sx={styles.teamIcon}
                            component="img"
                            src={require('../../resources/icons/team-icon.png')}
                        />
                    </Box>
                </Box>
                <Box sx={styles.imageSliderWrapper}>
                    <ImageSlider
                        content={timovi}
                        lgImageWidth="70%"
                        mdImageWidth="75%"
                        smImageWidth="70%"
                        xsImageWidth="90%"
                    />
                </Box>
            </Box>
        </Box>
    );
};
