/* eslint-disable */
import React, { useState } from 'react';
import axios from '../../api/axios';
import {
    FormControl,
    Grid,
    Typography,
    TextField,
    IconButton,
    InputAdornment,
    Button,
    Checkbox,
    Link,
    Divider,
    Alert,
    Box,
    Stack
} from '@mui/material';
import {
    gridContainerStyle,
    gridItemStyle,
    topTextStyle,
    textFieldStyle,
    buttonStyle,
    buttonTextStyle,
    gdprLinkStyle,
    tosStyle,
    subtitleStyle,
    alertBoxStyle,
    alertStyle
} from '../../styles';
import { VisibilityOff, Visibility } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

type LoginResponse = {
    success: boolean;
    status: string;
    message: string;
    data: {
        access: string;
        refresh: string;
    };
};

type LoginFormData = {
    username: string | undefined;
    password: string | undefined;
    rememberMe: boolean | false;
};

export const LoginForm = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState<LoginFormData>();

    const [formErrors, setFormErrors] = useState({ username: '', password: '', submit: '' });

    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const validateForm = () => {
        const errors = {
            username: '',
            password: '',
            submit: ''
        };

        if (formData != null) {
            if (!formData.username) {
                errors.username = 'Username is required';
            }

            if (!formData.password) {
                errors.password = 'Password is required';
            }

            setFormErrors(errors);
        }

        return !Object.values(errors).some((error) => error);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        if (validateForm()) {
            try {
                const response = await axios.get<LoginResponse>('/login', {
                    auth: {
                        username: formData?.username as string,
                        password: formData?.password as string
                    }
                });

                const { access, refresh } = response.data.data;

                localStorage.setItem('access_token', access);
                localStorage.setItem('refresh_token', refresh);

                setFormData({ username: '', password: '', rememberMe: false });

                if (response.status == 200) {
                    navigate('/home');
                }
            } catch (error) {
                setFormErrors((prevErrors) => ({ ...prevErrors, submit: 'Invalid credentials' }));
            }
        }
    };

    return (
        <FormControl>
            <Grid container spacing={0} sx={gridContainerStyle}>
                <Grid item xs={12} sx={gridItemStyle}>
                    <TextField
                        id="username"
                        type="text"
                        label="E-mail adresa"
                        style={textFieldStyle}
                        value={formData != null ? formData.username : ''}
                        onKeyDown={(e) => {
                            e.key == 'Enter' ? handleSubmit(e) : null;
                        }}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                username: e.target.value
                            } as LoginFormData)
                        }
                        className="custom-placeholder"
                    />
                </Grid>

                <Grid item xs={12} sx={gridItemStyle}>
                    <TextField
                        id="password"
                        type={showPassword ? 'text' : 'password'}
                        label="Lozinka"
                        style={textFieldStyle}
                        value={formData != null ? formData.password : ''}
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                password: e.target.value
                            } as LoginFormData)
                        }
                        onKeyDown={(e) => {
                            e.key == 'Enter' ? handleSubmit(e) : null;
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end">
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        className="custom-placeholder"
                    />
                </Grid>

                <Grid item xs={12} sx={gridItemStyle}>
                    <Button sx={buttonStyle('red')} onClick={handleSubmit}>
                        <Typography sx={buttonTextStyle()}>Prijavi se</Typography>
                    </Button>
                </Grid>

                <Grid item xs={1} sx={gridItemStyle}>
                    <Checkbox
                        name="rememberMe"
                        onChange={(e) =>
                            setFormData({
                                ...formData,
                                rememberMe: e.target.checked
                            } as LoginFormData)
                        }
                        checked={formData?.rememberMe}
                    />
                </Grid>
                <Grid item xs={3} sx={gridItemStyle}>
                    <Typography sx={tosStyle}>Zapamti me</Typography>
                </Grid>

                <Grid item sx={gridItemStyle}>
                    <Stack>
                        <Box sx={alertBoxStyle}>
                            {formErrors.submit && (
                                <Alert severity="error" sx={alertStyle}>
                                    {formErrors.submit}
                                </Alert>
                            )}
                        </Box>
                    </Stack>
                </Grid>
            </Grid>
            <Divider />

            <Grid container spacing={0} sx={gridContainerStyle}>
                <Grid item xs={12} sx={gridItemStyle}>
                    <Typography sx={subtitleStyle}>Još nemaš korisnički račun?</Typography>
                </Grid>
                <Grid item xs={12} sx={gridItemStyle}>
                    <Button sx={buttonStyle('#29C76F')} onClick={() => navigate('/register')}>
                        <Typography sx={buttonTextStyle()}>Postani novi član</Typography>
                    </Button>
                </Grid>
            </Grid>
        </FormControl>
    );
};
