import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import {
    centeredStyle,
    centeredStyleRow,
    landingPageTitle,
    landingPageText,
    componentWrapper,
    componentContainer
} from '../../styles';

const styles = {
    componentWrapper: {
        ...componentWrapper,
        backgroundColor: '#B00000',
        backgroundImage: 'url(/images/background-images/croatia-background-image.png)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain'
    },
    componentContainer: {
        ...componentContainer,
        alignItems: 'center',
        justifyContent: 'space-evenly',
        height: {
            xs: '90%',
            md: '80%'
        },
        width: {
            xs: '90%',
            md: '80%'
        }
    },
    pillContainer: {
        display: 'flex',
        justifyContent: 'space-evenly'
    },
    pill: {
        ...centeredStyle,
        backgroundColor: '#FFFFFF',
        borderRadius: '20px',
        height: {
            xs: '100px',
            md: '150px'
        },
        width: {
            xs: '100px',
            md: '150px'
        }
    },
    pillTitle: {
        ...landingPageTitle,
        fontFamily: 'Roboto, sans-serif',
        color: '#E03434',
        marginTop: '0px',
        fontSize: {
            xs: '2rem',
            md: '3.5rem'
        }
    },
    pillText: {
        ...landingPageText,
        fontFamily: 'Roboto, sans-serif',
        color: '#E03434',
        fontSize: {
            xs: '0.9rem',
            md: '1.4rem'
        }
    },
    textContainer: {
        ...centeredStyleRow,
        flexWrap: 'wrap',
        backgroundColor: '#C33031',
        border: '1px solid #F8EEEE',
        borderRadius: '32px',
        padding: '20px',
        justifyContent: 'space-evenly',
        marginTop: {
            xs: '1rem',
            md: '3rem'
        }
    },
    landingPageText: {
        ...landingPageText,
        maxWidth: {
            xs: '90%'
        },
        textAlign: 'center',
        fontSize: {
            xs: '1.2rem',
            sm: '1.7rem',
            md: '2rem'
        }
    },
    landingPageTitle: {
        ...landingPageTitle,
        marginTop: '0px',
        textAlign: 'center',
        fontSize: {
            xs: '3rem',
            sm: '3.5rem',
            md: '5rem'
        }
    }
};

export const TkoSmoMiPage = () => {
    return (
        <Box sx={styles.componentWrapper}>
            <Box sx={styles.componentContainer}>
                <Typography sx={[styles.landingPageText, { borderBottom: '1px solid #F8EEEE' }]}>
                    TKO SMO MI?
                </Typography>
                <Typography sx={styles.landingPageTitle}>EESTEC LC ZAGREB</Typography>
                <Typography sx={styles.landingPageText}>
                    EESTEC LC Zagreb studentska je udruga namijenjena svim studentima STEM područja.
                    Trenutno brojimo stotinjak aktivnih članova koji se radom u udruzi iz dana u dan
                    usavršavaju u interdisciplinarnim kao i stručnim područjima. Od samog početka
                    radimo na razvoju međunarodnih kontakata, razmjeni ideja i unaprjeđenju znanja
                    izvan nastavnih okvira.
                </Typography>
                <Grid container spacing={3} sx={styles.pillContainer}>
                    <Grid item xs={6} sm={6} md={3} lg={2} sx={centeredStyle}>
                        <Box sx={styles.pill}>
                            <Typography sx={styles.pillTitle}>16</Typography>
                            <Typography sx={styles.pillText}>GODINA</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={2} sx={centeredStyle}>
                        <Box sx={styles.pill}>
                            <Typography sx={styles.pillTitle}>5000</Typography>
                            <Typography sx={styles.pillText}>ČLANOVA</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={2} sx={centeredStyle}>
                        <Box sx={styles.pill}>
                            <Typography sx={styles.pillTitle}>42</Typography>
                            <Typography sx={styles.pillText}>SVEUČILIŠTA</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6} md={3} lg={2} sx={centeredStyle}>
                        <Box sx={styles.pill}>
                            <Typography sx={styles.pillTitle}>24</Typography>
                            <Typography sx={styles.pillText}>DRŽAVE</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};
