import React, { useState } from 'react';
import { LoginForm } from './LoginForm';
// import { RegisterForm } from './RegisterForm';
import { RegisterTemporary } from './RegisterTemporary';
import SuccessModal from './SuccessModal';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Box, Card, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import logoRed from '../../resources/logo-red.png';
import { colors } from '../../themes/theme';

type GuestPageProps = {
    isLogin: boolean;
};

const slogan = {
    fontFamily: 'Barlow',
    fontWeight: 'bold',
    fontSize: '2rem',
    width: '70%',
    textAlign: 'center'
};

const centered = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
};

const styles = {
    mainContainer: {
        padding: '1%',
        height: '100%',
        width: '100%',
        textAlign: 'center'
    },
    grid: {
        justify: 'flex-end',
        alignItems: 'center'
    },
    logo: {
        position: 'absolute',
        margin: '3% 5%',
        width: '8.813rem',
        height: '5.125rem'
    },
    gridItem: { padding: '2rem 3rem 0 3rem', width: '100%' },
    bannerContainer: {
        ...centered,
        maxHeight: '100%',
        maxWidth: '100%',
        margin: '4.56rem 0rem'
    },
    sloganText: slogan,
    redText: { ...slogan, color: colors.redText },
    description: {
        width: '70%',
        fontFamily: 'Roboto, sans-serif',
        fontSize: '1.5rem',
        fontStyle: 'normal',
        fontWeight: 'light',
        lineHeight: 'normal'
    },
    formCard: {
        width: '35rem',
        margin: { xs: '0rem auto 2rem', md: '6rem 0 2rem' },
        padding: { xs: '2rem 0.5rem', sm: '4.4rem 3.25rem' },
        boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.25)',
        borderRadius: '1.5rem'
    }
};

export const GuestPage = ({ isLogin = true }: GuestPageProps) => {
    const navigate = useNavigate();
    const [registrationSuccess, setRegistrationSuccess] = useState(false);

    const handleRegistrationSuccess = (): void => {
        setRegistrationSuccess(true);
    };
    const handleCloseModal = () => {
        setRegistrationSuccess(false);
        navigate('/home');
    };

    return (
        <>
            <Link href="/home">
                <Box component="img" alt="Home Page" src={logoRed} sx={styles.logo} />
            </Link>
            <Box sx={styles.mainContainer}>
                <Grid container sx={styles.grid}>
                    <Grid item xs={12} md={6}>
                        <Box sx={styles.gridItem} display="flex">
                            <Grid container>
                                <Grid item xs={12} sx={styles.bannerContainer}>
                                    <Typography sx={styles.sloganText} align="center">
                                        {' '}
                                        <Typography component="span" sx={styles.redText}>
                                            LEARN.{' '}
                                        </Typography>
                                        TRAVEL.{' '}
                                        <Typography component="span" sx={styles.redText}>
                                            MAKE FRIENDS.{' '}
                                        </Typography>
                                        IMPROVE YOURSELF.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sx={centered}>
                                    <Typography sx={styles.description} align="center">
                                        Pridruži se članovima EESTEC LC Zagreba i postani dio naše
                                        energične zajednice. Ovdje ćeš upoznati nove ljude,
                                        sudjelovati u projektima, putovati svijetom i uživati na
                                        našim partyima. Tražiš mjesto za razvoj i zabavu? Ne čekaj,
                                        pridruži nam se i kreiraj nove avanture s nama!
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box
                            sx={styles.gridItem}
                            display="flex"
                            justifyContent="left"
                            alignItems="left">
                            {!isLogin ? (
                                <Card sx={styles.formCard}>
                                    <RegisterTemporary
                                        onRegistrationSuccess={handleRegistrationSuccess}
                                    />
                                </Card>
                            ) : (
                                <Card sx={styles.formCard}>
                                    <LoginForm />
                                </Card>
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            <SuccessModal open={registrationSuccess} handleClose={handleCloseModal} />
        </>
    );
};
