import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import {
    centeredStyle,
    landingPageTitle,
    landingPageText,
    componentWrapper,
    componentContainer
} from '../../styles';
import { ImageSlider } from '../common/ImageSlider';

const odbor = [
    {
        role: 'Predsjednik',
        email: 'chairperson@eestec.hr',
        name: 'Lovro Šantek',
        image: '/images/odbor/odbor_1.jpg'
    },
    {
        role: 'Odnosi s javnošću',
        email: 'pr@eestec.hr',
        name: 'Karlo Vrančić',
        image: '/images/odbor/odbor_2.jpg'
    },
    {
        role: 'Kontakt osoba',
        email: 'cp@eestec.hr',
        name: 'Klara Zagajski',
        image: '/images/odbor/odbor_3.jpeg'
    },
    {
        role: 'Ljudski resursi',
        email: 'hr@eestec.hr',
        name: 'Filip Buljan',
        image: '/images/odbor/odbor_4.jpeg'
    },
    {
        role: 'Potpredsjednik',
        email: 'fr@eestec.hr',
        name: 'Libor Zima',
        image: '/images/odbor/odbor_5.jpeg'
    },
    {
        role: 'Tajnica',
        email: 'treasurer@eestec.hr',
        name: 'Nina Anić',
        image: '/images/odbor/odbor_6.jpg'
    }
];

const odborTextStyle = {
    fontFamily: 'Verdana',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '0em',
    textAlign: 'right',
    fontSize: '1.5rem',
    color: {
        xs: 'white',
        md: 'black'
    }
};

const styles = {
    landingPageTitle: {
        ...landingPageTitle,
        fontSize: {
            xs: '2.5rem',
            sm: '3.5rem',
            lg: '5rem'
        }
    },
    landingPageText: {
        ...landingPageText,
        fontSize: {
            xs: '1rem',
            sm: '1.3rem',
            lg: '1.5rem'
        }
    },
    odborInfoContainer: {
        borderRight: {
            xs: 'none',
            md: '1px solid black'
        },
        paddingRight: '10px'
    },
    odborRole: {
        ...odborTextStyle
    },
    odborEmail: {
        ...odborTextStyle,
        fontStyle: 'italic'
    },
    odborName: {
        ...odborTextStyle,
        fontFamily: 'Barlow, sans-serif',
        fontWeight: 700,
        fontSize: {
            sm: '2.5rem',
            xs: '1.5rem'
        }
    },
    redBackground: {
        width: '100%',
        height: '100vh',
        zIndex: -2,
        position: 'absolute',
        backgroundColor: '#B00000',
        top: 0
    },
    imageSliderWrapper: {
        height: {
            md: '100%',
            sm: '80%',
            xs: '70%'
        },
        width: '100%'
    }
};

export const OdborPage = () => {
    const odborImages = odbor.map((e) => e.image);
    const [currentIndex, setCurrentIndex] = useState(0);

    const handleIndexChange = (newIndex: number) => {
        setCurrentIndex(newIndex);
    };
    return (
        <Box sx={componentWrapper}>
            <Box sx={[componentContainer, { justifyContent: 'flex-start' }]}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={6} sx={{ position: 'relative' }}>
                        <Box sx={{ padding: '10px' }}>
                            <Typography sx={styles.landingPageTitle}> UPRAVNI ODBOR</Typography>
                            <Typography sx={styles.landingPageText}>
                                Vodstvo udruge čini Upravni odbor koji se odabire na Godišnjoj
                                sjednici udruge na kraju tekuće akademske godine te je izglasan od
                                strane aktivnih članova.
                            </Typography>
                        </Box>
                        <Box sx={styles.redBackground}></Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={12}
                        md={6}
                        sx={[centeredStyle, { alignItems: 'flex-end' }]}>
                        <Box sx={styles.odborInfoContainer}>
                            <Box sx={styles.odborRole}>{odbor[currentIndex].role}</Box>
                            <Box sx={styles.odborEmail}>{odbor[currentIndex].email}</Box>
                            <Box sx={styles.odborName}>{odbor[currentIndex].name}</Box>
                        </Box>
                    </Grid>
                </Grid>

                <Box sx={[centeredStyle, { height: '100%', width: '100%' }]}>
                    <Box sx={styles.imageSliderWrapper}>
                        <ImageSlider
                            images={odborImages}
                            onIndexChange={handleIndexChange}
                            lgImageWidth="40%"
                            mdImageWidth="60%"
                            smImageWidth="70%"
                            xsImageWidth="95%"
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
