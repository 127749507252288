import { CSSProperties } from 'react';
import Image from './resources/landing_page_background.png';

export const centeredStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
};

export const centeredStyleRow: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
};

export const gridItemStyle: CSSProperties = {
    margin: '0.3rem 0',
    padding: '0 0.8rem',
    ...centeredStyle
};

export const gridContainerStyle: CSSProperties = {
    width: '100%',
    height: '100%',
    flexShrink: 0
};

export const topTextStyle: CSSProperties = {
    fontSize: '1.5rem',
    fontWeight: '700',
    lineHeight: '38px',
    letterSpacing: '0',
    textAlign: 'left',
    padding: '0 0 2rem'
};

export const subtitleStyle: CSSProperties = {
    fontSize: '1.5rem',
    fontWeight: '400',
    color: '#828282',
    fontStyle: 'normal',
    lineHeight: '28.13px',
    letterSpacing: '0',
    textAlign: 'center',
    padding: '2rem 2rem 0'
};

export const textFieldStyle: CSSProperties = {
    width: '100%'
};

export const buttonStyle = (color: string = '#5486F3'): CSSProperties => ({
    borderRadius: '0.625rem',
    background: color,
    width: '100%',
    height: '3.75rem',
    margin: '2rem 0 0 0',
    padding: '2rem 2rem 2rem 2rem'
});

export const buttonTextStyle = (
    color: string = '#FFF',
    isBold: boolean = false
): CSSProperties => ({
    color: color,
    fontSize: isBold ? '2.5rem' : '1.5rem',
    fontWeight: isBold ? 700 : 300,
    fontStyle: 'normal',
    lineHeight: 'normal'
});

export const tosStyle: CSSProperties = {
    textAlign: 'center',
    color: '#828282',
    fontSize: '0.875rem',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal'
};

export const gdprLinkStyle: CSSProperties = {
    marginLeft: '0.25rem',
    fontSize: 'inherit',
    textDecoration: 'underline'
};

export const alertBoxStyle: CSSProperties = {
    ...centeredStyle
    //margin: '0.5rem 0'
};

export const landingPageBackgroundStyle: CSSProperties = {
    backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.43) 43%, #AC0404 78.65%), url(${Image})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    margin: 0,
    padding: 0,
    height: '100%'
};

export const alertStyle: CSSProperties = { width: '100%' };

export const sloganStyle: CSSProperties = {
    fontFamily: 'Barlow, sans-serif',
    fontWeight: 'bold',
    fontSize: '5rem',
    width: '100%',
    textAlign: 'center'
};

export const mainContainerStyle: CSSProperties = {
    padding: '1%',
    height: '100%',
    width: '100%',
    textAlign: 'center'
};

export const gridStyle: CSSProperties = {
    justifyContent: 'flex-end',
    alignItems: 'center'
};

export const logoStyle: CSSProperties = {
    position: 'absolute',
    margin: '3% 5%',
    width: '8.813rem',
    height: '5.125rem'
};

export const bannerGridItemStyle: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
};

export const bannerContainerStyle: CSSProperties = {
    ...centeredStyle,
    maxHeight: '100%',
    maxWidth: '100%',
    margin: '4.56rem 0rem'
};

export const description: CSSProperties = {
    width: '70%',
    fontSize: '1.5rem',
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: 'normal'
};

export const formCard: CSSProperties = {
    width: '35rem',
    margin: '0 0 2rem',
    padding: '4.4rem 3.25rem',
    boxShadow: '4px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    borderRadius: '1.5rem'
};

export const bannerWhiteTextStyle: CSSProperties = { ...sloganStyle, color: 'white' };

export const landingPageTitle: CSSProperties = {
    marginTop: '2rem',
    fontFamily: 'Barlow, sans-serif',
    fontSize: '3.5rem',
    fontWeight: 800,
    lineHeight: 'normal',
    letterSpacing: '0em',
    color: '#F8EEEE',
    textAlign: 'left'
};
export const landingPageText: CSSProperties = {
    fontFamily: 'Barlow, sans-serif',
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '0em',
    color: '#F8EEEE',
    textAlign: 'left'
};

export const componentWrapper: CSSProperties = {
    ...centeredStyle,
    position: 'relative',
    height: '100vh'
};

export const componentContainer: CSSProperties = {
    ...centeredStyle,
    height: '100%',
    width: '80%'
};

export const footerWrapper: CSSProperties = {
    ...centeredStyle,
    position: 'relative',
    height: '100%'
};

export const footerText: CSSProperties = {
    ...centeredStyle,
    margin: '1rem 0',
    color: '#F8F8F8',
    fontFamily: 'Arial',
    lineHeight: 'normal',
    textAlign: 'center',
    whiteSpace: 'pre-line'
};
