/* eslint-disable */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import {
    bannerContainerStyle,
    bannerGridItemStyle,
    bannerWhiteTextStyle,
    buttonStyle,
    buttonTextStyle,
    gridContainerStyle,
    gridItemStyle,
    landingPageBackgroundStyle,
    sloganStyle,
    subtitleStyle,
    componentWrapper
} from '../../styles';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { Box, Card, CardMedia, Grid, Typography } from '@mui/material';
import { colors } from '../../themes/theme';
import ImageDesktop from '../../resources/landing_page_background.png';
import ImageMobile from '../../resources/landing_page_background_mobile.png';

const styles = {
    landingPageBackgroundStyle: {
        ...landingPageBackgroundStyle,
        backgroundImage: {
            xs: `linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.43) 43%, #B00000 78.65%), url(${ImageMobile})`,
            sm: `linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.43) 43%, #B00000 78.65%), url(${ImageDesktop})`
        },
        backgroundPosition: 'right'
    },
    bannerWhiteFirstTextStyle: {
        ...bannerWhiteTextStyle,
        fontSize: {
            xs: '2.5rem',
            sm: '2.6rem',
            md: '3.5rem',
            lg: '4.5rem',
            xl: '5rem'
        }
    },
    bannerWhiteSecondTextStyle: {
        ...bannerWhiteTextStyle,
        fontSize: {
            xs: '3rem',
            sm: '3.5rem',
            md: '4.5rem',
            lg: '5rem',
            xl: '7rem'
        }
    },
    buttonText: {
        fontSize: {
            xs: '1rem',
            sm: '2rem',
            lg: '3rem'
        }
    }
};

export const PocetnaPage = () => {
    const navigate = useNavigate();
    return (
        <Box sx={componentWrapper}>
            <Box sx={styles.landingPageBackgroundStyle}>
                <Grid container spacing={0} sx={gridContainerStyle}>
                    <Grid item xs={12} sx={gridItemStyle}>
                        <CardMedia
                            component="img"
                            image={require('../../resources/logo-white.png')}
                            sx={{
                                height: { xs: 150, sm: 233, md: 200 },
                                width: { xs: 270, sm: 420, md: 360 }
                            }}
                            alt="EESTEC LC Zagreb"
                        />
                    </Grid>
                    <Grid item xs={12} sx={bannerGridItemStyle}>
                        <Box>
                            <Typography sx={styles.bannerWhiteFirstTextStyle}>
                                LEARN. TRAVEL. MAKE FRIENDS.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sx={bannerGridItemStyle}>
                        <Box>
                            <Typography sx={styles.bannerWhiteSecondTextStyle}>
                                IMPROVE YOURSELF.
                            </Typography>
                        </Box>
                    </Grid>
                    {!localStorage.getItem('access_token') && (
                        <Grid item xs={12} sx={gridItemStyle}>
                            <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center">
                                <Button
                                    sx={{
                                        borderRadius: '0.625rem',
                                        background: 'white',
                                        width: '100%',
                                        height: '3.75rem',
                                        padding: '2rem 2rem 2rem 2rem',
                                        ':hover': {
                                            bgcolor: 'primary.main', // theme.palette.primary.main
                                            color: 'red'
                                        }
                                    }}
                                    //onClick={() => navigate('/login')}
                                    onClick={() => navigate('/register')}>
                                    <Typography
                                        sx={[
                                            styles.buttonText,
                                            {
                                                color: '#5F021F',
                                                fontSize: '2.5rem',
                                                fontWeight: 700,
                                                fontStyle: 'normal',
                                                lineHeight: 'normal',
                                                ':hover': {
                                                    bgcolor: 'primary.main', // theme.palette.primary.main
                                                    color: 'white'
                                                }
                                            }
                                        ]}>
                                        UČLANI SE!
                                    </Typography>
                                </Button>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </Box>{' '}
        </Box>
    );
};
