import { createTheme } from '@mui/material/styles';
import 'typeface-barlow';
import 'typeface-roboto';

export const theme = createTheme({
    palette: {
        primary: {
            main: '#b00000',
            dark: '#9f2e2e',
            light: '#cd2828',
            contrastText: '#ffeeee'
        },
        secondary: {
            main: '#f50057'
        },
        background: {
            default: '#f8f8f8'
        }
    },
    typography: {
        fontFamily: ['Roboto', 'Barlow', 'Helvetica'].join(','),
        button: {
            textTransform: 'none'
        }
    }
});

export const colors = {
    redText: '#e61e25'
};
