/* eslint-disable */
export const ValidateEmail = (mail) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail);
};

export const ValidatePassword = (pass) => {
  console.log(pass.length);
  return pass && pass.length >= 8;
};

export const ValidatePhoneNumber = (phoneNumber) => {
  return /^\+?\d{7,18}$/.test(phoneNumber);
};

export const openExtraTab = (route) => {
  window.open(route, 'rel=noopener noreferrer');
};
