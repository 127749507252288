import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { centeredStyleRow, centeredStyle } from '../../styles';

type ImageSliderProps = {
    images?: string[];
    content?: ImageSliderContent[];
    // eslint-disable-next-line no-unused-vars
    onIndexChange?: (newIndex: number) => void;
    wrapperHeight?: string;
    lgImageWidth?: string;
    mdImageWidth?: string;
    smImageWidth?: string;
    xsImageWidth?: string;
};

type ImageSliderContent = {
    title: string;
    text: string;
    image: string;
};

const backgroundImageStyle = {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: ' no-repeat',
    width: {
        lg: '50%',
        md: '50%',
        sm: '80%',
        xs: '80%'
    }
};

const styles = {
    aspectRatio: {
        height: 'inherit'
    },
    imageSliderWrapper: {
        ...centeredStyle,
        height: '100%',
        width: '100%'
    },
    imageSliderContainer: {
        height: '100%',
        width: '100%',
        padding: '5px'
    },
    centerImageTextContainer: {
        position: 'absolute',
        zIndex: 11,
        top: 0,
        width: '100%',
        height: '100%'
    },
    centerImageTitle: {
        zIndex: 11,
        padding: '20px',
        fontFamily: 'Barlow, sans-serif',
        fontSize: {
            sm: '2.5rem',
            xs: '1.5rem'
        },
        fontWeight: 800,
        lineHeight: 'normal',
        letterSpacing: '0em',
        textAlign: 'center',
        color: '#FFFFFF'
    },
    centerImageText: {
        padding: '20px',
        zIndex: 11,
        fontFamily: 'Barlow, sans-serif',
        fontSize: {
            md: '1.7rem',
            sm: '1.3rem',
            xs: '1.1rem'
        },
        fontWeight: 600,
        lineHeight: 'normal',
        letterSpacing: '0em',
        textAlign: 'center',
        color: '#FFFFFF'
    },
    centerImageContainer: {
        ...centeredStyleRow,
        ...backgroundImageStyle,
        height: 'inherit',
        zIndex: 10,
        position: 'relative'
    },
    rightImageContainer: {
        ...centeredStyleRow,
        ...backgroundImageStyle,
        height: 'inherit',
        position: 'absolute',
        transform: 'scale(0.8)',
        right: 0,
        filter: 'brightness(50%)'
    },
    leftImageContainer: {
        ...centeredStyleRow,
        ...backgroundImageStyle,
        height: 'inherit',
        position: 'absolute',
        transform: 'scale(0.8)',
        left: 0,
        filter: 'brightness(50%)'
    },
    redTint: {
        backgroundColor: '#8E0F13',
        opacity: 0.7,
        position: 'absolute',
        zIndex: 11,
        width: '100%'
    },
    imageSliderButton: {
        height: { md: '40px', xs: '1.6rem' },
        width: { md: '40px', xs: '1.6rem' },
        borderRadius: '100px',
        minWidth: '20px',
        backgroundColor: 'white',
        boxShadow: '1px 2px 2px 1px  rgba(0,0,0,.8)',
        '&:hover': {
            bgcolor: 'white'
        }
    },
    imageSliderArrows: {
        height: {
            sm: '90%',
            xs: '100%'
        }
    }
};

export const ImageSlider = ({
    images,
    content,
    onIndexChange,
    lgImageWidth,
    mdImageWidth,
    smImageWidth,
    xsImageWidth
}: ImageSliderProps) => {
    if (content) {
        images = content.map((e) => e.image);
    }

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const previousImageIndex = (currentImageIndex - 1 + images!.length) % images!.length;
    const nextImageIndex = (currentImageIndex + 1) % images!.length;

    if (lgImageWidth) {
        backgroundImageStyle.width.lg = lgImageWidth;
    }
    if (mdImageWidth) {
        backgroundImageStyle.width.md = mdImageWidth;
    }
    if (smImageWidth) {
        backgroundImageStyle.width.sm = smImageWidth;
    }
    if (xsImageWidth) {
        backgroundImageStyle.width.xs = xsImageWidth;
    }

    return (
        <Grid container sx={styles.imageSliderWrapper}>
            <Grid item xs={12} sm={11} md={10} sx={[centeredStyleRow, styles.imageSliderContainer]}>
                <Button
                    sx={styles.imageSliderButton}
                    onClick={() => {
                        setCurrentImageIndex(previousImageIndex);
                        if (onIndexChange) {
                            onIndexChange(previousImageIndex);
                        }
                    }}>
                    <Box
                        sx={styles.imageSliderArrows}
                        component="img"
                        src={require('../../resources/icons/arrow-left-red.png')}
                    />
                </Button>

                <Box sx={[centeredStyleRow, styles.imageSliderContainer, { position: 'relative' }]}>
                    <Box
                        sx={[styles.leftImageContainer, styles.aspectRatio]}
                        style={{ backgroundImage: `url(${images![previousImageIndex]})` }}>
                        {content && <Box sx={[styles.redTint, styles.aspectRatio]} />}
                    </Box>

                    <Box
                        sx={[styles.centerImageContainer, styles.aspectRatio]}
                        style={{ backgroundImage: `url(${images![currentImageIndex]})` }}>
                        {content && <Box sx={[styles.redTint, styles.aspectRatio]} />}

                        {content && (
                            <Box sx={[styles.centerImageTextContainer, centeredStyle]}>
                                <Typography sx={styles.centerImageTitle}>
                                    {content[currentImageIndex].title}
                                </Typography>
                                <Typography sx={styles.centerImageText}>
                                    {content[currentImageIndex].text}
                                </Typography>
                            </Box>
                        )}
                    </Box>

                    <Box
                        sx={[styles.rightImageContainer, styles.aspectRatio]}
                        style={{ backgroundImage: `url(${images![nextImageIndex]})` }}>
                        {content && <Box sx={[styles.redTint, styles.aspectRatio]} />}
                    </Box>
                </Box>

                <Button
                    sx={styles.imageSliderButton}
                    onClick={() => {
                        setCurrentImageIndex(nextImageIndex);
                        if (onIndexChange) {
                            onIndexChange(nextImageIndex);
                        }
                    }}>
                    <Box
                        sx={styles.imageSliderArrows}
                        component="img"
                        src={require('../../resources/icons/arrow-right-red.png')}
                    />
                </Button>
            </Grid>
        </Grid>
    );
};
